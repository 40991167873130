// GraphLocationsdata.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import BarGraph from "../components/location/BarGraph";
import MapGraph from "../components/location/MapGraph";
import "../components/css/GraphLocationsdata.css"
const GraphLocationsdata = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("http://89.116.20.162:8081/locations/all");

      // const response = await axios.get("http://localhost:8081/locations/all");

      // const response = await axios.get("http://192.168.1.86:8081/locations/all");


      setLocations(response.data);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data: " + error.message);
      setLoading(false);
    }
  };

  const processDataForGraphs = () => {
    const categories = [
      "Offices",
      "Cinema",
      "Residences",
      "Rest",
      "Malls",
      "Schools",
    ];
    const completedCounts = categories.map((category) => {
      const filtered = locations.filter(
        (loc) => loc.category === category && loc.status === "Completed"
      );
      return { category, count: filtered.length };
    });
    return completedCounts;
  };

  if (loading) return <div className="loader">Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
          <div className="containerrr">
      <div className="text-contentt">
        {/* <h5 className="italic-headingg">A Comprehensive Observational Study</h5> */}
        <h3> SAFE IAQ ( Study and Assessment for Formulating Environmental best practices for Indoor Air Quality)</h3>
        <p>A comprehensive observational study in the field of indoor air quality evaluation through the project entitled</p>

        <p className="descriptionn">
         'Evaluation of Air Quality in Different Indoor Environments in the
          Mumbai Metropolitan Region (MMR), as Representative of Maharashtra
          State with Special Reference to Non-Attainment Areas and Severely
          Impacted Areas, and Formulation of Best Practices for Enhanced
          Indoor Air Quality'
        </p>
      </div>
      <div className="map-containerr">
        <MapGraph locations={locations} />
      </div>
    </div>
      <BarGraph data={processDataForGraphs()} />
    </div>
  );
};

export default GraphLocationsdata;
